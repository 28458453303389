import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import BolForm from "./BolForm"
import { Col, Layout, Menu, Row, Select, Space } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { ConfigContext, en, zh } from './ConfigContext';

const { Option } = Select;

function App() {

  var [lang, setLang] = useState("en");

  return (
    <div className="App">
      <Layout>
        <Header>
          <Menu theme="dark" mode="horizontal" selectable={false}>
            <Menu.Item key="logo"> <img src="logo.png" height="40" /></Menu.Item>

            <Menu.Item key="language" style={{ marginLeft: 'auto' }}>
              <Select defaultValue="en" style={{ width: 120 }} onChange={(value: string) => {
                if (value == "zh") {
                  setLang("zh");
                }
                else {
                  setLang("en");
                }
              }}
              >
                <Option value="en">English</Option>
                <Option value="zh">简体中文</Option>
              </Select>
            </Menu.Item>
          </Menu>


        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <ConfigContext.Provider value={{ language: lang == "en" ? en : zh }}>

              <Row>
                <Col span={6}></Col>
                <Col span={12}>

                  <BolForm /></Col>


                <Col span={6}></Col>

              </Row>
            </ConfigContext.Provider>

          </div>
        </Content>

        <Footer >©2021 Lalafirst LLC All Rights Reserved</Footer>
      </Layout>
    </div >
  );
}

export default App;
