import React, { useState, useEffect } from 'react';
import {
    Form,
    InputNumber,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
    message,
    Space,
    DatePicker,
    Divider,
    Input,
    Typography
} from 'antd';

import { getByPlaceholderText } from '@testing-library/dom';
import BolOrderInfo from "./BolOrderInfo";
import { ConfigContext, en, zh } from './ConfigContext';

import Axios from 'axios';

const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;



const { RangePicker } = DatePicker;
var alreadyLoad = false;
var pdfUrl = "";

const BolForm = (props: any) => {
    const [form] = Form.useForm();
    const [paymentMethod, setPaymentMethod] = useState("transfer");
    const [agreement, setAgreement] = useState(true);
    const [bolDdata,setBolData] = useState(false);
    const [isLoading,setLoading] = useState(false);


    const [orderInfo,setOrderInfo] = useState({});
    var token: any;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        token = params.get('token'); // 123 

        if(!token)
        {
            token = "";
        }

        console.log(`${JSON.stringify(process.env)}`);


        if(!alreadyLoad)
        {
            Axios.get(`${process.env.REACT_APP_API_HOST}/order/bol/info/${token}`).then((obj: any) => {
            
                var data = obj.data;
    
                if (data && data.catalog) {
    
                    var acceptableDate = "";
                    var deliveryDate = "";

                    var note = "";
                    
                    try
                    {
                        var pickupDate = data.catalog.pickUpDate;
                        var pickupTime = data.catalog.pickUpTime;
                        
                        var dropOffDate = data.catalog.dropOffDate;
                        var dropOffTime = data.catalog.dropOffTime;
                        // var 
                        pickupDate = pickupDate?new Date(pickupDate).toDateString():"";
                        pickupTime = pickupTime?new Date(pickupTime).toLocaleTimeString('en-US'):"";
                        acceptableDate = `${pickupDate} ${pickupTime}`.trim();//
    
                        // delivery
                        dropOffDate = dropOffDate?new Date(dropOffDate).toDateString():"";
                        dropOffTime = dropOffTime?new Date(dropOffTime).toLocaleTimeString('en-US'):"";
                        deliveryDate = `${dropOffDate} ${dropOffTime}`.trim();//

                        if(data.bol)
                        {
                            pdfUrl = data.url;
                            console.log(pdfUrl);
                            setBolData(true);                            
                        }

                        if(data.catalog.typeOfPallet)
                        {
                            note += `Type of Pallet: ${data.catalog.typeOfPallet}\n`;
                        }

                        if(data.catalog.avgWeight)
                        {
                            note += `Avg Weight: ${data.catalog.avgWeight}\n`;
                        }

                        if(data.catalog.avgHeight)
                        {
                            note += `Avg Height: ${data.catalog.avgHeight}\n`;
                        }

                        if(data.catalog.spaceOccupied)
                        {
                            note += `Space Occupied: ${data.catalog.spaceOccupied}\n`;
                        }

                        if(data.catalog.palletQuantity)
                        {
                            note += `Pallet Quantity: ${data.catalog.palletQuantity}\n`;
                        }

                        if(data.catalog.overWeightPallet)
                        {
                            note += `No.of Overweight Pallet: ${data.catalog.overWeightPallet}\n`;
                        }


                        if(data.catalog.maxWeight)
                        {
                            note += `Max Weight: ${data.catalog.maxWeight}\n`;
                        }

                        if(data.catalog.l && data.catalog.w && data.catalog.h)
                        {
                            note += `L x W x H: ${data.catalog.l} x ${data.catalog.w} x ${data.catalog.h}\n`;
                        }
                        
                    }
                    catch(ex)
                    {
    
                    }
    
    
                    form.setFieldsValue({
                        from_name: '',
                        from_address: `${data.catalog.originAddress},${data.catalog.originZip},(${data.catalog.originResidential?"Residential":"Commercial"})`,
                        from_city: data.catalog.originCity,
                        to_name: "",
                        to_address: `${data.catalog.destinationAddress},${data.catalog.destinationZip},(${data.catalog.destinationResidential?"Residential":"Commercial"})`,
                        to_city: data.catalog.destinationCity,
                        to_acceptable_date: acceptableDate,
                        to_delivery_date: deliveryDate,
                        to_need_lift_gate: data.catalog.needLiftGate == 1?true:false,
                        price: `$ ${data.catalog.price}`,
                        note: note,
                        ref: `${data.ref}`
                    });
    
                    alreadyLoad  = true;
    
                }
            });
        }


    });

    const onFinish = (values: any) => {
        values.billing = paymentMethod;
        values.orderInfo = orderInfo;

        values.token = token;

        var url = `${process.env.REACT_APP_API_HOST}/bol/create`;

        setLoading(true);

        console.log(url);
        Axios.post(url, values).then((obj) => {
            console.log(obj.data.url);
            console.log(values);
            window.location.href  = obj.data.url;
            // window.open(obj.data.url, '_blank', 'noopener,noreferrer');
            message.success('Submit success!');
        });
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const onFill = () => {
        // form.setFieldsValue({
        //     url: 'https://taobao.com/',
        // });
    };


    const getByPlaceholderText = (text: string) => {

        return `Please input ${text}`;
    }

    return (
        <ConfigContext.Consumer>
            {({ language }) =>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        name="price"
                        label={language.Bol_To_Price}
                    >
                     <Input style={{ width: "100%",color:"#FF0000" }} readOnly disabled />
                    </Form.Item>


                    <Form.Item
                        name="ref"
                        label={language.Bol_To_Ref}
                    >
                     <Input style={{ width: "100%",color:"#000000" }} readOnly disabled />
                    </Form.Item>

                    <Form.Item
                        name="date"
                        label={language.Bol_Date}
                    >
                        <DatePicker placeholder={getByPlaceholderText(language.Bol_Date)} style={{ width: "100%" }}  disabled={bolDdata} />
                    </Form.Item>

                    <Divider orientation="left">SHIP FROM</Divider>

                    <Form.Item
                        name="from_name"
                        label={language.Bol_From_Name}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_Name)} style={{ width: "100%" }}  disabled={bolDdata}/>
                    </Form.Item>

                    <Form.Item
                        name="from_address"
                        label={language.Bol_From_Address}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_Address)} style={{ width: "100%" }} disabled={bolDdata} />
                    </Form.Item>


                    <Form.Item
                        name="from_city"
                        label={language.Bol_From_City_State_Zip}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_City_State_Zip)} style={{ width: "100%" }}  disabled={bolDdata}/>
                    </Form.Item>

                    <Divider orientation="left">SHIP TO</Divider>
                    <Form.Item
                        name="to_name"
                        label={language.Bol_From_Name}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_Name)} style={{ width: "100%" }}  disabled={bolDdata}/>
                    </Form.Item>

                    <Form.Item
                        name="to_address"
                        label={language.Bol_From_Address}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_Address)} style={{ width: "100%" }}  disabled={bolDdata}/>
                    </Form.Item>

                    <Form.Item
                        name="to_city"
                        label={language.Bol_From_City_State_Zip}>
                        <Input placeholder={getByPlaceholderText(language.Bol_From_City_State_Zip)} style={{ width: "100%" }}  disabled={bolDdata}/>
                    </Form.Item>

                    <Form.Item
                        name="to_need_lift_gate" valuePropName="checked">
                        <Checkbox style={{ width: "100%" }}  disabled={bolDdata}>{language.Bol_To_Need_Lift_Gate}</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="to_acceptable_date"
                        label={language.Bol_To_Acceptable_Date}>
                        <Input placeholder={getByPlaceholderText(language.Bol_To_Acceptable_Date)} style={{ width: "100%" }}  disabled={bolDdata}/>

                    </Form.Item>


                    <Form.Item
                        name="to_delivery_date"
                        label={language.Bol_To_Date_Delivery}>
                        <Input placeholder={getByPlaceholderText(language.Bol_To_Date_Delivery)} style={{ width: "100%" }}  disabled={bolDdata}/>

                    </Form.Item>


                    <Divider orientation="left">BILLING</Divider>
                    <Form.Item
                        name="billing"
                        label={language.Bol_Billing_Payment_Method} valuePropName="option">
                        <Select  disabled={bolDdata} defaultValue={paymentMethod} style={{ float: 'left', width: 200 }} onChange={(value: string) => {
                            setPaymentMethod(value);
                        }}
                        >
                            {/* <Option value="check">{language.Bol_Billing_Type_Check}</Option> */}
                            <Option value="transfer">{language.Bol_Billing_Type_Transfer}</Option>
                            <Option value="zelle">{language.Bol_Billing_Type_Zelle}</Option>

                        </Select>
                    </Form.Item>
                    {paymentMethod == "transfer" ?   
                    <Typography>
                        <Paragraph style={{ textAlign: 'left' }}>
                            <Text strong>Recipient: LALAFIRST LLC</Text><br />
                            <Text strong>Bank: JPMorgan Chase Bank, N.A.</Text><br />
                            <Text strong>Account number: 777380962</Text><br />
                            <Text strong>Routing number: 322271627</Text><br />
                        </Paragraph>
                        <Paragraph style={{ textAlign: 'left' }}>
                        <Text strong>Note:</Text> By filling up and submitting the Bill of Lading, you have agreed to the terms and conditions of Lalafirst LLC’s services, including but not limited to provide timely payment to Lalafirst LLC upon the completion of delivery. Full delivery fees shall be paid to LALAFIRST LLC within 7 working days upon the completion of delivery. Late payment penalty of 1% per day will be charged to you and Lalafirst LLC shall retain the rights to claim any potential costs or loss of revenue associated with the collection of the delayed payments.
                        </Paragraph>
                    </Typography>
:
                 <Typography>
                        <Paragraph style={{ textAlign: 'left' }}>
                            <Text strong>Email: lang@lalatruck.com</Text><br />
                        </Paragraph>
                        <Paragraph style={{ textAlign: 'left' }}>
                        <Text strong>Note:</Text> By filling up and submitting the Bill of Lading, you have agreed to the terms and conditions of Lalafirst LLC’s services, including but not limited to provide timely payment to Lalafirst LLC upon the completion of delivery. Full delivery fees shall be paid to LALAFIRST LLC within 7 working days upon the completion of delivery. Late payment penalty of 1% per day will be charged to you and Lalafirst LLC shall retain the rights to claim any potential costs or loss of revenue associated with the collection of the delayed payments.                        </Paragraph>
                    </Typography>
                    }
                    {/* <Divider>{language.Bol_Order_Infomation_Title}</Divider>                                        
                    <BolOrderInfo onValueChanged={(data: any) => {
                        setOrderInfo(data);
                    }} /> */}

                    <Divider>{language.Bol_Miscellaneous}</Divider>

                    <Form.Item>
                        <Row >
                            <Col>
                                <Form.Item name="contain_dangerous" valuePropName="checked">
                                    <Checkbox  disabled={bolDdata}> {language.Bol_Contain_Dangerous} </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="contain_flammable_materials" valuePropName="checked">

                                    <Checkbox  disabled={bolDdata}> {language.Bol_Contain_Flammable_Materials}  </Checkbox>
                                </Form.Item>

                            </Col>
                            <Col>
                                <Form.Item name="contain_fragile_materials" valuePropName="checked">
                                    <Checkbox  disabled={bolDdata}> {language.Bol_Contain_Fragile_Materials}  </Checkbox>
                                </Form.Item>

                            </Col>
                            <Col>
                                <Form.Item name="goods_can_be_staked" valuePropName="checked">
                                    <Checkbox  disabled={bolDdata}> {language.Bol_Goods_can_be_staked} </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form.Item>

                    <Divider>{language.Bol_Note}</Divider>

                    <Form.Item name="note">
                        <TextArea  disabled={bolDdata} rows={4} />

                    </Form.Item>

{bolDdata?<div><a href={pdfUrl}>Bol have been created, Click here</a></div>:
                    <Form.Item name="agreement" valuePropName="checked">
                                    <Checkbox onChange = { (e) => {
                                        setAgreement(!e.target.checked)                                        
                                    }

                                    }
                                    > {language.BOL_Agreement} </Checkbox>
                    </Form.Item>}

                    <div style={{ height: 20 }} />
                    
                    <Form.Item>
                        <Space>
                        {bolDdata? null:
                            <Button type="primary" htmlType="submit"  disabled={agreement} loading={isLoading}>
                                {language.BOL_Submit}
                            </Button>
}
                        </Space>
                    </Form.Item>
                </Form >
                
            }
        </ConfigContext.Consumer>
    );

};


export default BolForm;

function useParams(): { id: any; } {
    throw new Error('Function not implemented.');
}
